<template>
  <!-- el-dialog / el-drawer 随意切换 -->
  <component
    :is="`el-${mode}`"
    class="min-h"
    :class="[mode === 'dialog' ? 'dialog-vertical consult-keyword' : '']"
    :title="dialogTitle"
    :visible="show"
    width="865px"
    append-to-body
    :modal="mode === 'dialog'"
    size="40%"
    @close="closeAdd"
  >
    <component :is="mode==='drawer'?'el-scrollbar':'div'" v-loading="!data" class="middle flex" :class="[mode === 'drawer' ? 'flex-col' : '']">
      <div><!-- 左边是预览区域 --></div>
      <div class="flex-col" :style="{ padding: mode === 'drawer' ? '0 20px' : '' }">
        <el-form
          :model="form"
          :rules="rules"
          class="small"
          label-position="right"
          label-width="125px"
          ref="consultServiceReplyFormRef"
        >
          <el-form-item label="关键词：" prop="name">
            <el-input v-model="form.name" placeholder="请输入" />
          </el-form-item>

          <el-form-item label="设置回复内容：" prop="contents">
            <input type="hidden" :value="form.contents" />
            <add-consult-service-reply-dialog-sort-list
              ref="AddConsultServiceReplyDialogSortListRef"
              v-model="form.contents"
            ></add-consult-service-reply-dialog-sort-list>
          </el-form-item>
        </el-form>
      </div>
    </component>

    <div class="flex-1"></div>
    <div v-if="mode === 'drawer'" class="drawer-footer flex-between">
      <div class="">
        <el-button v-if="form.id" type="danger" size="small" @click="handleDel">删除</el-button>
      </div>

      <div>
        <el-button size="small" @click="closeAdd">取消</el-button>
        <el-button
            size="small"
            type="primary"
            :loading="saveLoading"
            :disabled="!data"
            @click="submit"
        >确定</el-button
        >
      </div>
    </div>

    <template #footer>
      <el-button size="small" @click="closeAdd">取消</el-button>
      <el-button
        size="small"
        type="primary"
        :loading="saveLoading"
        :disabled="!data"
        @click="submit"
        >确定</el-button
      >
    </template>
  </component>
</template>

<script>
import AddConsultServiceReplyDialogSortList from './AddConsultServiceReplyDialogSortList'
import { saveConsultServiceReplyItem } from '../../../api/consult-service-reply'
import { randomString } from '../../../../../base/utils/tool'
const JUMP_LINK_MODEL = require('@/base/json/jump-link.json')

const formModel = {
  id: '',
  name: '',
  contents: [
    // 回复内容数组
    {
      type: 'text', // 'text', 'article', 'subject', 'activity', 'venue', 'survey', 'link', 'keyword', 'service',
      data_id: '', // 数据混淆 ID（ 'article', 'subject', 'activity', 'venue', 'survey', 'keyword',）
      id: '', // 前端自己的随机id key
      config: {
        text: '',
        ...JUMP_LINK_MODEL,
        // 活动
        name: '',
        activity_time_text: '',
        address_name: '',
        // activity: {
        //   name: ''
        // },
        // subject: {
        //   name: ''
        // },
        // 文章专题
        // name: '',
        // article: {
        //   name: ''
        // },
        // 文章
        title: '',
        post_time: '',
        region: '',
        description: '',
        category_name: '',
        source_name: '',
        // 关键词
        create_time: '',
        // keywords: [{
        //   name: ''
        // }],
        // 场地
        // name: '',
        address: {
          lat: 40.061371,
          lng: 116.307945,
          name: '',
          address: '',
          address_name: '',
        },
        organization_name: '',
        reserve_time_text_2: '',
        // venue: {
        //   name: ''
        // },
        // survey: {
        //   name: ''
        // },
        // service: {
        //
        // }
        button_name: '',
      }, // 额外配置（ 'text', 'link',）具体见下面
    },
  ],
}

/**
 * // text 配置
 {
 "text": ""
}

 // link 配置与当前页面装修跳转链接一致
 {
    "jump_type": "",
    "jump_config": {}
}
 */

export default {
  name: 'AddConsultServiceReplyDialog',
  components: { AddConsultServiceReplyDialogSortList },
  model: {
    prop: 'open',
    event: 'handleOpen',
  },
  props: {
    // 是否打开
    open: {
      type: Boolean,
      required: true,
    },
    data: Object,
    mode: {
      type: String,
      default: 'dialog',
    },
  },
  data() {
    return {
      form: this.$mergeModel(formModel, {}),
      rules: {
        name: [
          {
            required: true,
            message: '请输入关键词',
            trigger: 'blur',
          },
        ],
        contents: [
          {
            required: true,
            message: '请设置回复内容',
            trigger: 'blur',
          },
          {
            validator: (rule, value, callback) => {
              if (!value || !value.length) {
                callback(new Error('请设置回复内容'))
              } else {
                callback()
              }
            },
          },
        ],
      },
      saveLoading: false,
      initLoading: false,
    }
  },
  computed: {
    show: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('handleOpen', val)
      },
    },
    dialogTitle() {
      return this.data?.id ? '编辑关键词' : '新建关键词'
    },
  },
  watch: {
    open(newValue, oldValue) {
      if (newValue) {
        this.$refs.consultServiceReplyFormRef?.resetFields()
      }
    },
    data(val) {
      if (this.open) {
        this.$nextTick(() => {
          // 回显数据
          this.form = this.$mergeModel(formModel, val || {})
          this.form.contents.forEach((content) => {
            if (!content.id) content.id = randomString(8)
          })

          console.log(this.form)
        })
      }
    },
  },
  methods: {
    closeAdd() {
      console.log('close')
      this.show = false
    },
    submit() {
      this.$refs.consultServiceReplyFormRef?.validate((valid) => {
        if (valid) {
          const valid2 =
            this.$refs.AddConsultServiceReplyDialogSortListRef?.doValidate()
          if (valid2) {
            // this.$message.success('全部校验通过')
            this.saveLoading = true

            saveConsultServiceReplyItem({
              ...this.form,
            })
              .then(({ code, data, msg }) => {
                if (code) {
                  this.$message.success(msg)

                  this.closeAdd()
                  this.$emit('refresh')
                } else {
                  this.$message.error(msg)
                }
              })
              .catch(() => {})
              .finally(() => {
                this.saveLoading = false
              })
          } else {
            this.$message.error('请填写完整信息')
          }
        } else {
          this.$message.error('请填写完整信息')
          this.$refs.AddConsultServiceReplyDialogSortListRef?.doValidate()
        }
      })
    },
    handleDel() {
      this.$confirm('是否将关键词【' + this.data.name + '】移入回收站？').then(() => {
        this.$bus.emit('removeConsultKeyword', this.data)
      }).catch(() => {
      })
      // this.$emit('del', this.data)
    }
  },
}
</script>

<style lang="scss">
.dialog-vertical.min-h {
  > .el-dialog {
    min-height: 700px;
    display: flex;
    flex-direction: column;

    .el-dialog__body {
      flex: 1;
      min-height: 0;
    }
  }

  .middle {
    min-height: 550px;
  }
}

.el-drawer__wrapper.min-h {
  .middle {
    min-height: 0;
  }

  .el-drawer {
    .subject-list {
      /*max-width: 460px;*/
      width: auto;
    }

    .drawer-footer {
      position: sticky;
      bottom: 0;
      padding: $space;
      background-color: white;
    }
  }

  .el-drawer__body {
    display: flex;
    flex-direction: column;
    overflow: hidden auto;

    /*.el-scrollbar .el-scrollbar__wrap .el-scrollbar__view:after {*/
    /*  margin-bottom: 0;*/
    /*}*/
  }
}

/* 这两句影响其他地方，应该动态添加 class 才是最佳方案 */
/*html {overflow: hidden;height: 100%;}*/
/*body {overflow: auto;height: 100%;}*/


</style>
