/**
 * 列表页和弹窗列表页共用的代码
 */
import { fetchConsultServiceReplyList } from '@/modules/consult/api/consult-service-reply'

export default {
  data() {
    return {
      form: {
        page: 1,
        keyword: '',

        page_size: 20,

        create_start_time: -1,
        create_end_time: -1,

        tab: 'normal', // normal 、deleted
      },
      // 弹窗不需要显示操作列，需要重新声明覆盖掉
      thead: [
        { type: 'selection' },
        { label: '关键词', prop: 'name', minWidth: 100 },
        { label: '关联上级', prop: 'from_edges', minWidth: 240, tooltip: true },
        { label: '创建时间', prop: 'create_time', minWidth: 180 },
        {
          label: '操作',
          type: 'operation',
          width: 120,
          operations: [
            {
              command: 'edit',
              text: '编辑',
              visible: (r, q) => q.tab !== 'deleted',
            },
            {command: 'putback', visible: (row, form) => form.tab === 'deleted'},
            {command: 'delete'},
          ],
        },
      ],

      addDialog: {
        show: false,
        data: null,
        loading: false,
        mode: 'dialog'
      },
    }
  },
  methods: {
    // 添加
    addConsultServiceReplyKeyword() {
      this.addDialog.data = {}
      this.addDialog.mode = 'dialog'
      this.addDialog.show = true
    },
    // 筛选回调
    ok(e) {
      this.onRefresh()
    },
    // 拉取数据
    getList(query) {
      return fetchConsultServiceReplyList(query)
    },
  },
}
