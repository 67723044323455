<template>
  <!--
          handle：手柄，只有手放在指定元素上面才能拖动"被拖动元素"
          draggable：被拖动元素
         -->

  <div class="single-subject">
    <el-form
      ref="AddConsultServiceReplyDialogSortListItem"
      class="small-form"
      :model="myValue"
      :rules="rules"
      label-width="0px"
      inline-message
      @submit.native.prevent
      :id="myValue.id"
    >
      <!--
                目前前端只校验了文本类型必填
              -->
      <el-form-item
        label=""
        :prop="myValue.type === 'text' ? 'config.text' : ''"
        class="item"
      >
        <!-- 操作栏 -->
        <div class="subject-btn">
          <div class="subject-head flex-align-center">
            <img
              class="item-dragger cursor-pointer"
              src="../../../assets/images/operation.png"
              alt=""
            />

            <span class="item-name">{{
              typeArr.find((t) => t.id === myValue.type)
                ? typeArr.find((t) => t.id === myValue.type).name
                : ''
            }}</span>
          </div>

          <!-- 删除确认，可选 -->
          <!--          <el-popconfirm title="确认删除？" @confirm="removeLine()">-->
          <i
            v-show="showDelete"
            slot="reference"
            class="el-icon-delete cursor-pointer"
            style="color: red"
            @click="removeLine()"
          ></i>
          <!--          </el-popconfirm>-->
        </div>

        <div class="divider"></div>

        <!--        <p>{{ myValue }}</p>-->

        <div class="content">
          <div v-if="myValue.type === 'text'" class="label">
            <el-input
              height="80px"
              type="textarea"
              class="textarea"
              v-model="myValue.config.text"
              placeholder="请输入"
            ></el-input>
          </div>

          <!--
                      关键词，一组数据中可以多选
                    -->

          <template v-if="myValue.type === 'keyword'">
            <div v-if="myValue.data_id" class="data-item keyword-item">
              <h3 class="keyword">
                {{ myValue.config ? myValue.config.name || '' : '' }}
              </h3>
              <p class="create-time">
                创建时间：{{
                  myValue.config ? myValue.config.create_time || '--' : ''
                }}
              </p>
            </div>

            <!-- <div class="keyword-item flex-between">
              {{ myValue.config.name }}

              <i
                @click="myValue.config.keywords.splice(keywordI, 1)"
                class="el-icon el-icon-error cursor-pointer"
                style="color: #bdc6d2; font-size: 18px"
              ></i>
            </div> -->

            <el-button
              v-if="myValue.config && myValue.config.name"
              @click="openKeywordDialog()"
              type="primary"
              plain
              style="border-color: #ebf1ff"
              >更换关键词</el-button
            >
            <el-button v-else @click="openKeywordDialog()" type="primary"
              >添加关键词</el-button
            >
          </template>

          <!-- 链接类型 -->
          <!-- 来自页面装修的链接设置组件 -->
          <st-link-config
            v-if="myValue.type === 'link'"
            v-model="myValue.config"
            size="small"
            style="width: 100%"
            placeholder="设置链接"
          ></st-link-config>

          <div v-if="myValue.type === 'service'">
            按钮名称：<el-input
              :disabled="true"
              :value="myValue.config ? myValue.config.button_name : ''"
            ></el-input>
          </div>

          <!-- 文章、活动、文章专题差不多类似 -->
          <template v-if="myValue.type === 'article'">
            <div v-if="myValue.data_id" class="data-item article-item">
              <h3 class="article-title">
                {{ myValue.config ? myValue.config.title || '' : '' }}
              </h3>
              <p class="article-summary">
                {{ myValue.config ? myValue.config.description || '--' : '' }}
              </p>
              <div class="article-footer flex flex-align-center">
                <p class="left">
                  <template
                    v-if="myValue.config && myValue.config.category_name"
                  >
                    <span
                      class="category"
                      v-for="cate in myValue.config.category_name.split('、')"
                    >
                      {{ cate }}</span
                    >
                  </template>
                </p>
                <p class="right">
                  {{ myValue.config ? myValue.config.source_name || '--' : '' }}
                  {{ myValue.config ? myValue.config.post_time || '--' : '' }}
                </p>
              </div>
            </div>
          </template>

          <!--
                            "formType": "st-special-article",
            "itemTitle": "文章专题",
            "addButtonText": "添加文章专题数据"
                    -->
          <template v-if="myValue.type === 'subject'">
            <div v-if="myValue.data_id" class="data-item subject-item">
              <h3 class="subject-title">
                {{ myValue.config ? myValue.config.name || '' : '' }}
              </h3>
              <p class="subject-desc">
                {{ myValue.config ? myValue.config.description || '--' : '' }}
              </p>
            </div>
          </template>
          <template v-if="myValue.type === 'activity'">
            <div
              v-if="myValue.data_id"
              class="data-item activity-item keyword-item"
            >
              <h3 class="keyword">
                {{ myValue.config ? myValue.config.name || '' : '' }}
              </h3>
              <p class="create-time">
                活动地址：{{
                  myValue.config ? myValue.config.address_name || '--' : ''
                }}
              </p>
              <p class="create-time">
                活动时间：{{
                  myValue.config
                    ? myValue.config.activity_time_text || '--'
                    : ''
                }}
              </p>
            </div>
          </template>

          <!-- 场地，这里复用的是活动地址的场地选择弹窗 -->
          <template v-if="myValue.type === 'venue'">
            <div
              v-if="myValue.data_id"
              class="data-item venue-item keyword-item"
            >
              <h3 class="keyword">
                {{ myValue.config ? myValue.config.name || '' : '' }}
              </h3>
              <p class="create-time">
                场地地址：{{
                  myValue.config && myValue.config.address
                    ? `${
                        myValue.config.address.name ||
                        myValue.config.address.address_name
                      }(${myValue.config.address.address})` || '--'
                    : ''
                }}
              </p>
              <p class="create-time">
                所属架构：{{
                  myValue.config ? myValue.config.organization_name || '--' : ''
                }}
              </p>
              <p class="create-time">
                开放时间：{{
                  myValue.config
                    ? myValue.config.reserve_time_text_2 || '--'
                    : ''
                }}
              </p>
            </div>
            <el-button
              v-if="!myValue.data_id"
              @click="$emit('pick', { type: 'venue', data: [] })"
              type="primary"
              >选择场地</el-button
            >
            <el-button
              v-else
              @click="$emit('pick', { type: 'venue', data: [myValue.data_id] })"
              type="primary"
              plain
              style="border-color: #ebf1ff"
              >更换场地</el-button
            >
          </template>

          <template v-if="myValue.type === 'survey'">
            <div
              v-if="myValue.data_id"
              class="data-item venue-item keyword-item"
            >
              <h3 class="keyword">
                {{ myValue.config ? myValue.config.name || '' : '' }}
              </h3>

              <p class="create-time">
                创建时间：{{
                  myValue.config ? myValue.config.create_time || '--' : ''
                }}
              </p>
            </div>
            <el-button
              v-if="!myValue.data_id"
              @click="$emit('pick', { type: 'survey', data: [] })"
              type="primary"
              >选择问卷</el-button
            >
            <el-button
              v-else
              @click="
                $emit('pick', { type: 'survey', data: [myValue.data_id] })
              "
              type="primary"
              plain
              style="border-color: #ebf1ff"
              >更换问卷</el-button
            >
          </template>

          <!-- 通用的按钮 -->
          <template
            v-if="['article', 'activity', 'subject'].includes(myValue.type)"
          >
            <el-button
              v-if="!myValue.data_id"
              @click="openDataSelector(myValue.type, [myValue.data_id])"
              type="primary"
              >选择{{
                typeArr.find((t) => t.id === myValue.type)
                  ? typeArr.find((t) => t.id === myValue.type).name
                  : ''
              }}</el-button
            >
            <el-button
              v-else
              @click="openDataSelector(myValue.type, [myValue.data_id])"
              type="primary"
              plain
              style="border-color: #ebf1ff"
              >更换{{
                typeArr.find((t) => t.id === myValue.type)
                  ? typeArr.find((t) => t.id === myValue.type).name
                  : ''
              }}</el-button
            >
          </template>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
const JUMP_LINK_MODEL = require('@/base/json/jump-link.json')

import draggable from 'vuedraggable'
import ConsultServiceReplyListDialog from './ConsultServiceReplyListDialog'
import LinkConfigDialog from '../../../../app-design/components/Normal/LinkConfigDialog'
import StLinkConfig from '../../../../app-design/components/Form/StLinkConfig'
import StLink from '../../../../app-design/components/Preview/StLink'
import DataSelector from '../../../../app-design/components/Normal/dataSelector'
import DadgeDialog from '../../../../activity/components/ActivityList/AddvenueSelector/index'
import { fetchConsultServiceConfig } from '../../../api/consult-service-reply'

export default {
  name: 'AddConsultServiceReplyDialogSortItem',
  components: {
    DadgeDialog,
    DataSelector,
    StLink,
    StLinkConfig,
    LinkConfigDialog,
    ConsultServiceReplyListDialog,
    draggable,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: true,

      rules: {
        'config.text': [
          { required: true, message: '请输入文本内容', trigger: 'change' },
        ],
      },

      /**
       *
       'text'     => '文本',
       'article'  => '文章',
       'subject'  => '专题',
       'activity' => '活动',
       'venue'    => '场地',
       'survey'   => '问卷',
       'link'     => '链接',
       'keyword'  => '关联关键词',
       'service'  => '接入客服',
       */
      typeArr: [
        { id: 'text', name: '文本' },
        { id: 'article', name: '文章' },
        { id: 'subject', name: '专题' },
        { id: 'activity', name: '活动' },
        { id: 'venue', name: '场地' },
        { id: 'survey', name: '问卷' },
        { id: 'link', name: '链接' },
        { id: 'keyword', name: '关联关键词' },
        { id: 'service', name: '接入客服' },
      ],

      valid: false,
    }
  },
  computed: {
    myValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },

  async created() {
    if (this.value.type === 'service') {
      // 获取客服按钮文案
      const {
        data: { service_button_name },
      } = await fetchConsultServiceConfig()

      if (service_button_name)
        this.myValue.config.button_name = service_button_name
    }
  },

  methods: {
    // 场地调整触发
    handleChangeAddvenue(value) {
      this.myValue.config.venue = value
      this.myValue.data_id = value.id
    },
    openDataSelector(type, dataList) {
      let formType // 转化为装修组件标识

      switch (type) {
        case 'article':
          formType = 'st-article-list'
          break
        case 'subject':
          formType = 'st-special-article'
          break
        case 'activity':
          formType = 'st-activity-list'
          break
        default:
          formType = ''
      }

      if (!formType) return

      this.$emit('pick', {
        type,
        formType,
        data: dataList,
      })
    },
    // 点击选择数据
    dataChange(val) {
      // this.dataSelectDialog.dataList = val; // [{id, name}]
      // this.myValue.data_id = val[0].id

      this.myValue.splice(
        this.currentI,
        1,
        ...val.map((v) => {
          // const propName = this.myValue.type

          return {
            type: this.myValue.type,
            data_id: v.id,
            config: {
              ...v,
            },
          }
        })
      )
    },
    // 移除
    removeLine() {
      // this.$confirm('是否删除此地址?', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      // })
      //   .then(() => {
      this.$emit('remove')
      //     this.$message({
      //       type: 'success',
      //       message: '删除成功!',
      //     })
      //   })
      //   .catch(() => {})
    },

    openKeywordDialog() {
      this.$emit('pick', {
        type: 'keyword',
        data: {},
      })
    },

    /**
     * 外部可以调用此方法校验
     * @returns {boolean}
     */
    doValidate() {
      this.valid = true

      this.$refs.AddConsultServiceReplyDialogSortListItem?.forEach((item) => {
        item.validate((valid) => {
          if (this.valid && !valid) {
            this.valid = false // 有非法输入
          }
        })
      })

      return this.valid // 输出校验结果
    },
  },
}
</script>

<style lang="scss" scoped>
.single-subject {
  // 链接类型
  ::v-deep .link-selected .link-name {
    flex: 1;
    width: unset;
    max-width: 80%;
  }

  .item {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 4px;
    color: #909399;
    /*min-height: 190px;*/
    padding-bottom: 12px;

    &:not(.is-error) {
      .el-form-item__error {
        position: absolute;
        bottom: -1em;
      }
    }

    .subject-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .divider {
      margin: 0 12px;
      border-top: 0.5px solid rgba(0, 0, 0, 0.1);
    }

    .content {
      margin: 11px 12px 0;

      .label {
        display: flex;
        /*margin-bottom: 20px;*/
        color: #606266;

        span {
          width: 90px;
          text-align: right;
          margin-right: 8px;
        }

        .tips {
          width: 194px;
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #999;
          line-height: 12px;
          margin-left: 10px;
        }

        .el-input {
          flex: 1;
          max-width: 400px;
        }

        .textarea {
          flex: 1;
          max-width: none;
        }

        ::v-deep .el-textarea__inner {
          height: 80px;
        }
      }
    }
  }

  .flex {
    display: flex;
    padding-top: 10px;

    span {
      width: 15px;
      display: inline-block;
      margin-right: 10px;
    }
  }
}

.subject-del-btn {
  width: 30px;
  text-align: right;
  cursor: pointer;
}

.el-form {
  width: 100%;
}

.subject-head {
  padding: 12px;
  position: relative;

  .item-name {
    margin-left: 8px;

    /*font-size: 12px;*/

    color: #1a1a1a;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  .item-dragger {
    transform: scale(0.7);
    transform-origin: center;
  }
}

.el-icon-delete {
  position: absolute;
  right: 12px;
  top: 1em;

  &:hover {
    color: red;
  }
}

.data-item {
  margin-bottom: 8px;
  background: #f8f9fb;
  border-radius: 4px;
}

.keyword-item {
  width: 100%;
  /*height: 53px;*/
  padding: 16px;
  /*display: inline-flex;*/
  /*align-items: center;*/
  display: block;

  font-size: 14px;

  .keyword {
    color: #1a1a1a;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
  }

  .create-time {
    color: #808080;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-top: 8px;
  }
}

.subject-item {
  padding: 16px;

  .subject-title {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    color: #1a1a1a;
  }

  .subject-desc {
    @include clamp(2);
    color: #808080;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-top: 8px;
  }
}

.article-item {
  /*width: 357px;*/
  min-height: 132px;
  flex-shrink: 0;
  background: #f8f9fb;
  padding: 16px 16px 12px;

  .article-title {
    color: #1a1a1a;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }

  .article-summary {
    @include clamp(2);
    color: #808080;
    font-size: 13px;
    font-style: normal;
    line-height: 20.8px;
    margin-top: 8px;
    padding-left: 10px;
    position: relative;

    &:before {
      content: '';
      width: 2px;
      height: 38px;
      flex-shrink: 0;
      border-radius: 21px;
      background: #dadfe6;
      display: block;
      position: absolute;
      left: 0;
    }
  }

  .article-footer {
    padding-top: 14px !important;

    .left,
    .right {
      line-height: 1.5 !important;
    }

    .left {
      flex: 1;

      .category {
        display: flex;
        padding: 1px 4px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: #def6ff;
        white-space: nowrap;
        flex-shrink: 0;
        width: fit-content !important;

        color: #17b6f0;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
    }

    .right {
      @include nowrap();
      color: #cbcbcb;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20.8px;
    }
  }
}
</style>

<style lang="scss">
.el-popconfirm__main {
  margin-bottom: $space * 0.5;
}
</style>
