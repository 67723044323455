<template>
  <div v-loading="pagesLoading" class="content flex">
    <!-- <div style="margin-right: 20px">
      <div style="width: 315px; height: 600px; overflow: auto"></div>
    </div> -->
    <div style="width: 600px">
      <el-form
        class="save-content"
        label-width="100px"
        :model="form"
        ref="saveForm"
      >
        <el-form-item label="客服平台：">
          <div class="flex-align-center" style="height: 40px">
            <el-switch
              :active-value="1"
              :inactive-value="0"
              v-model="form.enable"
            ></el-switch>
            <div class="form-tips" style="margin: 0; margin-left: 8px">
              <i
                class="el-icon-info"
              />开启后，相关页面会显示“联系客服”入口，用户可通过引导与您联系
            </div>
          </div>
        </el-form-item>
        <h2 class="config-section-title">欢迎设置</h2>
        <el-form-item label="客服名称：">
          <el-input v-model="form.nickname"></el-input>
        </el-form-item>
        <el-form-item label="客服头像：">
          <single-media-wall
            v-model="form.avatar"
            :width="80"
            :height="80"
            ratio="160:160"
          >
          </single-media-wall>
        </el-form-item>
        <el-form-item label="欢迎语：">
          <el-input
            type="textarea"
            maxlength="50"
            show-word-limit
            v-model="form.greeting"
          ></el-input>
        </el-form-item>
        <el-form-item label="客服反馈：">
          <el-input
            type="textarea"
            maxlength="50"
            show-word-limit
            v-model="form.service_greeting"
          ></el-input>
          <div class="form-tips">
            <i
              class="el-icon-info"
            />暂无客服在线时，联系客服时反馈页面的显示文案
          </div>
        </el-form-item>
        <h2 class="config-section-title">自动回复配置</h2>
        <el-form-item label="配置关键词：">
          <!-- <el-input v-model="form.has_keyword"></el-input> -->
          <el-button @click="keywordDialog.show = true" type="primary" plain>
            配置
          </el-button>
          <div v-if="!form.has_keyword" class="form-tips">
            <i class="el-icon-info" />暂无关键词，需要先新建关键词
          </div>
        </el-form-item>
        <el-form-item
          v-if="form.default_keywords && form.default_keywords.length"
          label="当前关键词："
        >
          <div class="keyword-list">
            <div
              class="keyword-item"
              v-for="(keyword, index) in form.default_keywords"
              :key="keyword.id"
            >
              {{ keyword.name }}
              <i
                @click="form.default_keywords.splice(index, 1)"
                class="el-icon-close"
              />
            </div>
          </div>
        </el-form-item>
        <h2 class="config-section-title">人工客服配置</h2>
        <!-- <el-form-item label="按钮名称：">
          <el-input v-model="form.service_button_name"></el-input>
        </el-form-item>
        <el-form-item label="咨询分类文案：">
          <el-input v-model="form.service_category_tips"></el-input>
        </el-form-item> -->
        <el-form-item label="咨询分类：">
          <el-button
            v-if="form.service_categories && !form.service_categories.length"
            @click="goCategoryList"
            type="primary"
            plain
            >新建</el-button
          >
          <el-checkbox
            v-for="item in form.service_categories"
            :key="item.id"
            @change="item.selected = item.selected ? 0 : 1"
            :checked="item.selected === 1"
            >{{ item.name }}</el-checkbox
          >
        </el-form-item>
        <!-- <el-form-item label="设置回复内容：">
          <el-input
            type="textarea"
            maxlength="50"
            show-word-limit
            v-model="form.service_reply_text"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="设置回复问卷：">
          <div
            v-if="form.service_survey && form.service_survey.id"
            class="flex-align-center"
          >
            <div class="service_survey">
              {{ form.service_survey.name }}
            </div>
            <el-button type="primary" @click="surveyDialog.show = true"
              >修改</el-button
            >
          </div>
          <el-button v-else type="primary" @click="surveyDialog.show = true"
            >选择问卷</el-button
          >
        </el-form-item> 
        <h2 class="config-section-title">关键词反馈</h2>
        <el-form-item label="关键词反馈：">
          <el-input
            type="textarea"
            maxlength="50"
            show-word-limit
            v-model="form.keyword_greeting"
          ></el-input>
          <div class="form-tips">
            <i
              class="el-icon-info"
            />当无法触发自动回复关键词时，反馈页面的显示文案
          </div>
        </el-form-item>
      </el-form>
      <FixedActionBar>
        <el-button type="primary" @click="saveConfig">保存</el-button>
      </FixedActionBar>
    </div>
    <!-- 选问卷 -->
    <consult-service-survey-list-dialog
      v-model="surveyDialog.show"
      :data="surveyDialog.show ? {} : null"
      @confirm="onSurveyConfirm"
      type="radio"
    ></consult-service-survey-list-dialog>

    <!-- 选关键词 -->
    <consult-service-reply-list-dialog
      v-model="keywordDialog.show"
      :data="keywordDialog.show ? {} : null"
      :show-add="true"
      @confirm="onKeywordConfirm"
      @click-add="addConsultServiceReplyKeyword"
    ></consult-service-reply-list-dialog>

    <!-- 添加关键词 -->
    <add-consult-service-reply-dialog
        v-model:open="addDialog.show"
        :data="addDialog.loading || !addDialog.show ? null : addDialog.data"
        @refresh="keywordDialog.show = true"
    ></add-consult-service-reply-dialog>
  </div>
</template>
<script>
import FixedActionBar from '@/base/layout/FixedActionBar'
import SingleMediaWall from '@/modules/common/components/SingleMediaWall.vue'
import ConsultServiceReplyListDialog from '@/modules/consult/components/consult-service/reply/ConsultServiceReplyListDialog'
import ConsultServiceSurveyListDialog from '@/modules/consult/components/consult-service/reply/ConsultServiceSurveyListDialog'
import {
  getServiceConfig,
  setServiceConfig,
} from '../../api/consult-service/config'
import AddConsultServiceReplyDialog from '../../components/consult-service/reply/AddConsultServiceReplyDialog'
export default {
  name: 'service-config-page',
  components: {
    AddConsultServiceReplyDialog,
    FixedActionBar,
    SingleMediaWall,
    ConsultServiceReplyListDialog,
    ConsultServiceSurveyListDialog,
  },
  data() {
    return {
      form: {},
      pagesLoading: true,
      surveyDialog: {
        show: false,
        data: null,
      },
      keywordDialog: {
        show: false,
        data: null,
        loading: false,
      },
      addDialog: {
        show: false,
        data: null,
        loading: false,
      },
    }
  },
  methods: {
    // 添加
    addConsultServiceReplyKeyword() {
      this.addDialog.data = {}
      this.addDialog.show = true
    },
    saveConfig() {
      setServiceConfig({ config: this.form }).then((res) => {
        this.$message.success('保存成功')
        this.getConfig()
      })
    },
    getConfig() {
      this.pagesLoading = true
      getServiceConfig().then((res) => {
        console.log(res)
        this.pagesLoading = false
        this.form = res.data
      })
    },
    goCategoryList() {
      this.$router.push({
        name: 'ConsultServiceCategory',
      })
    },
    onSurveyConfirm(dataList = []) {
      this.form.service_survey = dataList[dataList.length - 1]
      this.surveyDialog.show = false
    },

    onKeywordConfirm(dataList = []) {
      dataList = dataList.filter(
        (item1) =>
          !this.form.default_keywords.some((item2) => item2.id === item1.id)
      )
      this.form.default_keywords.push(...dataList)
      this.keywordDialog.show = false
    },
  },
  mounted() {
    this.getConfig()
  },
}
</script>
<style lang="scss" scoped>
.form-tips {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #b3b3b3;
  line-height: 1.5;
  margin-top: 8px;
  span + span {
    margin-left: 4px;
  }
}
.keyword-list {
  display: flex;
  width: 100%;
  padding: 6px 12px;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  flex-wrap: wrap;
  .keyword-item {
    display: flex;
    padding: 0 10px;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #e9e9eb;
    background: #f4f4f5;
    color: #909399;
    font-size: 14px;
    height: 28px;
    font-weight: 400;
  }
}
.service_survey {
  max-width: 100px;
  margin-right: 10px;
  // height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
