<template>
  <div v-loading="!typeArr.length">
    <div class="add-subjects">
      <div class="subject-list" v-show="myValue.length !== 0">
        <!--
          handle：手柄，只有手放在指定元素上面才能拖动"被拖动元素"
          draggable：被拖动元素
         -->
        <draggable
          v-model="myValue"
          animation="500"
          @end="changeSort"
          draggable=".draggable"
          handle=".item-dragger"
        >
          <div
            class="draggable"
            v-for="(subject, index) in myValue"
            :key="subject.id"
            :id="subject.id"
          >
            <add-consult-service-reply-dialog-sort-item
              ref="consultServiceReplySortItem"
              v-model="myValue[index]"
              :show-delete="true || myValue.length > 1"
              @pick="onPickData(index, $event)"
              @remove="myValue.splice(index, 1)"
            ></add-consult-service-reply-dialog-sort-item>
          </div>
        </draggable>
      </div>
      <p v-if="myValue.length" class="img-tips">提示：拖拽可以调整排序</p>

      <!-- 新建选项 -->
      <div class="new">
        <el-form label-position="top">
          <!-- 点击之后往内容列表添加一项，还需再手动点击一次按钮来选数据 -->
          <el-form-item label="新建回复内容：">
            <div class="type-list flex">
              <template v-for="(type, typeI) in typeArr">
                <div @click="addLine(type.id)" class="type-item" :key="type.id">
                  <!--                <img src="" />-->
                  <span>{{ type.name }}</span>
                </div>
              </template>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 选关键词 -->
    <consult-service-reply-list-dialog
      v-model:open="keywordDialog.show"
      :data="keywordDialog.show ? {} : null"
      :show-add="false"
      @confirm="onKeywordConfirm"
    ></consult-service-reply-list-dialog>

    <!-- 选文章、文章专题、活动 -->
    <dataSelector
      v-model="dataSelectDialog.show"
      :selected="dataSelectDialog.dataList"
      :dataType="dataSelectDialog.formType"
      @change="dataChange"
      :form="dataSelectDialog.pageData"
      select-full-data
    />

    <!-- 选场地 -->
    <dadge-dialog
      v-model:open="venueSelector.show"
      @change="handleChangeAddvenue"
      append-to-body
      select-full-data
    />

    <!-- 选问卷 -->
    <consult-service-survey-list-dialog
      v-model:open="surveyDialog.show"
      :data="surveyDialog.show ? {} : null"
      @confirm="onSurveyConfirm"
    ></consult-service-survey-list-dialog>
  </div>
</template>

<script>
import { randomString } from '../../../../../base/utils/tool'

const JUMP_LINK_MODEL = require('@/base/json/jump-link.json')

import draggable from 'vuedraggable'
import ConsultServiceReplyListDialog from './ConsultServiceReplyListDialog'
import LinkConfigDialog from '../../../../app-design/components/Normal/LinkConfigDialog'
import StLinkConfig from '../../../../app-design/components/Form/StLinkConfig'
import StLink from '../../../../app-design/components/Preview/StLink'
import DataSelector from '../../../../app-design/components/Normal/dataSelector'
import DadgeDialog from '../../../../activity/components/ActivityList/AddvenueSelector/index'
import AddConsultServiceReplyDialogSortItem from './AddConsultServiceReplyDialogSortItem'
import ConsultServiceSurveyListDialog from './ConsultServiceSurveyListDialog'
import {fetchReplyTypes} from '../../../api/consult-service/survey-list'

export default {
  name: 'AddConsultServiceReplyDialogSortList',
  components: {
    ConsultServiceSurveyListDialog,
    AddConsultServiceReplyDialogSortItem,
    DadgeDialog,
    DataSelector,
    StLink,
    StLinkConfig,
    LinkConfigDialog,
    ConsultServiceReplyListDialog,
    draggable,
  },
  model: {
    prop: 'value',
    event: 'changeValue',
  },
  props: {
    value: {
      type: Array,
    },
  },
  data() {
    return {
      loading: true,

      myValue: [],

      // 当前操作项的索引
      currentI: -1,

      rules: {
        'config.text': [
          { required: true, message: '请输入文本内容', trigger: 'change' },
        ],
      },

      /**
       *
       'text'     => '文本',
       'article'  => '文章',
       'subject'  => '专题',
       'activity' => '活动',
       'venue'    => '场地',
       'survey'   => '问卷',
       'link'     => '链接',
       'keyword'  => '关联关键词',
       'service'  => '接入客服',
       */
      typeArr: [
        // { id: 'text', name: '文本' },
        // { id: 'article', name: '文章' },
        // { id: 'subject', name: '专题' },
        // { id: 'activity', name: '活动' },
        // { id: 'venue', name: '场地' },
        // // { id: 'survey', name: '问卷' },
        // { id: 'link', name: '链接' },
        // { id: 'keyword', name: '关联关键词' },
        // { id: 'service', name: '接入客服' },
      ],

      valid: false,

      keywordDialog: {
        show: false,
        data: null,
        loading: false,
      },

      linkDialog: {
        show: false,
        data: null,
        loading: false,
      },

      dataSelectDialog: {
        show: false,
        dataList: [],
        formType: 'st-article-list',
        pageData: {},
      },

      venueSelector: {
        show: false,
      },

      surveyDialog: {
        show: false,
        data: null
      },
    }
  },
  watch: {
    value: {
      handler(val) {
        // console.log(val);

        this.myValue = val
        if (this.myValue) {
          this.myValue.forEach((item) => {})

          if (val.length) {
          } else {
            // this.addLine('text') // 自动增加一条内容
          }
        }

        if (val && !this.typeArr.length && !this._typeLoading) {
          this._typeLoading = true
          fetchReplyTypes().then(({data}) => {
            this.typeArr = data.map(el => ({
              ...el,
              id: el.value,
              name: el.label
            }));
          }).catch(() => {
          }).finally(() => {
            this._typeLoading = false
          })
        }
      },
      immediate: true,
      // deep: true,
    },
  },
  mounted() {
    if (this.myValue) {
      this.myValue.forEach((item) => {})
    }
  },
  methods: {
    onSurveyConfirm(dataList = []) {
      this.myValue.splice(
        this.currentI,
        1,
        ...dataList.map((el) => {
          return {
            id: randomString(8), // 前端自己加的唯一id
            type: 'survey',
            data_id: el.id,
            config: el,
          }
        })
      )

      this.surveyDialog.show = false
    },
    // 场地调整触发
    handleChangeAddvenue(value) {
      console.log(value)
      this.myValue[this.currentI].config = value
      this.myValue[this.currentI].data_id = value.id
      this.myValue[this.currentI].id = randomString(8) // 前端自己加的唯一id
    },
    openDataSelector(rowIndex, type, dataList) {
      let formType // 转化为装修组件标识

      switch (type) {
        case 'article':
          formType = 'st-article-list'
          break
        case 'subject':
          formType = 'st-special-article'
          break
        case 'activity':
          formType = 'st-activity-list'
          break
        default:
          formType = ''
      }

      if (!formType) return

      this.currentI = rowIndex
      this.dataSelectDialog.formType = formType
      this.dataSelectDialog.dataList = dataList
      this.dataSelectDialog.show = true
    },
    // 点击选择数据
    dataChange(val) {
      this.myValue.splice(
        this.currentI,
        1,
        ...val.map((v) => {
          return {
            type: this.myValue[this.currentI].type,
            data_id: v.id,
            id: randomString(8),
            config: {
              ...v,
            },
          }
        })
      )
    },
    // 移除
    removeLine(i) {
      // this.$confirm('是否删除此地址?', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      // })
      //   .then(() => {
      this.myValue.splice(i, 1)
      //     this.$message({
      //       type: 'success',
      //       message: '删除成功!',
      //     })
      //   })
      //   .catch(() => {})
    },
    // 添加
    addLine(type) {
      this.myValue.push({
        id: randomString(8), // 前端自己加的唯一id
        type,
        data_id: '',
        config: getTypeConfig(type), // 不一定有哦
      })

      this.$emit('changeValue', this.myValue)

      function getTypeConfig(type) {
        switch (type) {
          case 'text':
            return { text: '' }
          case 'link': {
            return JSON.parse(JSON.stringify(JUMP_LINK_MODEL))
          }
          case 'service': {
            return { button_name: '' }
          }
          case 'keyword': {
            return {
              keywords: [],
            }
          }
          case 'survey':
          case 'other':
          default:
            return null
        }
      }
    },

    openKeywordDialog() {
      // TODO: 各种弹窗
      this.keywordDialog.data = {}
      this.keywordDialog.show = true
    },

    onKeywordConfirm(dataList = []) {
      console.log(dataList)

      this.myValue.splice(
        this.currentI,
        1,
        ...dataList.map((el) => {
          return {
            id: randomString(8), // 前端自己加的唯一id
            type: 'keyword',
            data_id: el.id,
            config: el,
          }
        })
      )

      this.keywordDialog.show = false
    },

    /**
     * 点击选择/更换【数据】按钮
     */
    onPickData(index, { type, formType, data }) {
      this.currentI = index

      switch (type) {
        case 'article':
        case 'activity':
        case 'subject': {
          this.dataSelectDialog.formType = formType
          this.dataSelectDialog.dataList = data
          this.dataSelectDialog.show = true
          break
        }
        case 'keyword':
          this.openKeywordDialog()
          break
        case 'venue':
          this.venueSelector.show = true
          break
        case 'survey':
          this.surveyDialog.show = true
          break
        default:
      }
    },

    // 顺序改变
    changeSort() {
      this.$emit('changeValue', this.myValue)
    },

    /**
     * 外部可以调用此方法校验
     * @returns {boolean}
     */
    doValidate() {
      this.valid = true

      this.$refs.consultServiceReplySortItem?.forEach((item) => {
        item.$refs.AddConsultServiceReplyDialogSortListItem?.validate(
          (valid) => {
            if (this.valid && !valid) {
              this.valid = false // 有非法输入
            }
          }
        )
      })

      return this.valid // 输出校验结果
    },
  },
}
</script>

<style lang="scss" scoped>
.add-subjects {
  color: #1a1a1a;

  .img-tips {
    width: 194px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #999;
    line-height: 12px;
    margin-top: 10px;

    & + .new {
      margin-top: $space * 0.5;
    }
  }

  .subject-list {
    width: 700px;

    display: flex;
    flex-direction: column;
    padding: 16px;
    background-color: #f5f7fa;
    border-radius: 4px;

    .draggable + .draggable {
      margin-top: 8px;
    }
  }
}

.el-form {
  width: 100%;
}

.type-list {
  flex-wrap: wrap;

  .type-item {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #f7f7f7;
    margin-right: 8px;
    margin-bottom: 8px;
    min-width: 72px;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      background-color: $primary;
      color: white;
    }

    span {
      font-size: 14px;
      line-height: normal;
    }
  }
}
</style>
