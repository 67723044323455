import {
  getSurveyIndex,
  softDelete,
  Delete,
  putBack,
} from '@/modules/consult/api/consult-service/survey-list.js'

export default {
  data() {
    return {
      thead: [
        // { type: 'selection' },
        // { label: '问卷ID', prop: 'id' },
        { label: '问卷名称', prop: 'name' },
        // { label: '问卷类型', prop: 'nickname' },
        { label: '创建时间', prop: 'create_time' },
        // {
        //   label: '操作',
        //   type: 'operation',
        //   operations: [
        //     {
        //       command: 'edit',
        //       text: '编辑',
        //       visible: (row, filter) => filter.tab !== 'deleted',
        //     },
        //     { command: 'putback', visible: (r, q) => q.tab === 'deleted' },
        //     { command: 'delete' },
        //   ],
        // },
      ],
      //筛选对象
      filterForm: {
        page: 1,
        page_size: 20,
        create_start_time: -1,
        create_end_time: -1,
        keyword: '',
        tab: 'normal',
      },
    }
  },
  methods: {
    onFetch(params) {
      return getSurveyIndex(params)
    },
    onDelete(idList, forever = false) {
      return forever ? Delete(idList) : softDelete(idList)
    },
    onPutBack(idList) {
      return putBack({ids: idList})
    },
    fetchData(page = 1) {
      this.$refs.surveyListRef.getList({ page })
    },
  }
}