import api from '@/base/utils/request'

export const fetchConsultServiceReplyList = (data) => {
  return api({ url: '/admin/consult/serviceReply/index', method: 'post', data })
}

export const saveConsultServiceReplyItem = (data) => {
  return api({ url: '/admin/consult/serviceReply/save', method: 'post', data })
}

export const fetchConsultServiceReplyItemDetail = (data) => {
  return api({
    url: '/admin/consult/serviceReply/detail',
    method: 'post',
    data,
  })
}

export const deleteConsultServiceReplyItemForever = (data) => {
  return api({ url: '/admin/consult/serviceReply/del', method: 'post', data })
}

export const deleteConsultServiceReplyItem = (data) => {
  return api({
    url: '/admin/consult/serviceReply/softDelete',
    method: 'post',
    data,
  })
}

/* 其他模块 */
export const fetchConsultServiceConfig = (data) => {
  return api({
    url: '/admin/consult/settings/getServiceConfig',
    method: 'post',
    data,
  })
}

/* 关系 */
export const getEdges = (data) => {
  return api({url:'/admin/consult/serviceReply/dag', method: 'post', data})
}