<template>
  <div>
    <el-dialog
      class="dialog-vertical"
      :title="dialogTitle"
      :visible="show"
      width="865px"
      append-to-body
      @close="closeAdd"
    >
      <div v-loading="!data" class="middle flex">
        <list-layout
          ref="surveyListRef"
          :filter-form="filterForm"
          :thead="thead"
          :on-fetch="onFetch"
          style="width: 100%"
          role="dialog"
        >
          <!--          <template #top>-->
          <!--            <div v-if="showAdd" style="margin-bottom: 20px">-->
          <!--              <el-button-->
          <!--                  type="primary"-->
          <!--                  size="small"-->
          <!--                  @click="addConsultServiceReplyKeyword"-->
          <!--              >新建关键词</el-button-->
          <!--              >-->
          <!--            </div>-->
          <!--          </template>-->
          <template #filter> </template>
        </list-layout>
      </div>

      <template #footer>
        <el-button size="small" @click="closeAdd">取消</el-button>
        <el-button
          size="small"
          type="primary"
          :loading="saveLoading"
          :disabled="!data"
          @click="submit"
          >确定</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>

<script>
import ListLayout from '../../../../../base/layout/ListLayout'
import consultServiceSurveyList from '../../../mixin/consultServiceSurveyList'

export default {
  name: 'ConsultServiceSurveyListDialog',
  components: {
    ListLayout,
  },
  mixins: [consultServiceSurveyList],
  model: {
    prop: 'open',
    event: 'handleOpen',
  },
  props: {
    // 是否打开
    open: {
      type: Boolean,
      required: true,
    },
    data: Object,
    type: {
      type: 'radio' | 'selection',
      default: 'selection',
    },
  },
  data() {
    return {
      rules: {},

      thead: [
        { type: 'selection' },
        // { type: 'radio' }, /* 启用单选 */
        { label: '问卷名称', prop: 'name', minWidth: 100, tooltip: true },
        // { label: '问卷类型', prop: 'type_text', minWidth: 240 },
        { label: '创建时间', prop: 'create_time', minWidth: 180 },
        // {
        //   label: '操作',
        //   type: 'operation',
        //   width: 120,
        //   operations: [
        //     { command: 'edit', text: '编辑' },
        //   ],
        // },
      ],

      saveLoading: false,
      initLoading: false,
    }
  },
  computed: {
    show: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('handleOpen', val)
      },
    },
    dialogTitle() {
      return '选择问卷'
    },
  },
  watch: {
    open(newValue, oldValue) {
      if (newValue) {
        // this.$refs.consultServiceReplyFormRef?.resetFields()
        this.thead.splice(0, 1, {
          type: this.$props.type,
        })
        this.$refs.surveyListRef?.clearSelection()
      }
    },
    data(val) {
      if (this.open) {
        this.$nextTick(() => {
          // 回显数据
          // this.form = this.$mergeModel(
          //     formModel,
          //     Object.assign({}, this.form, val)
          // )
          //
          // console.log(this.form)
        })
      }
    },
  },
  methods: {
    closeAdd() {
      console.log('close')
      this.show = false
    },

    submit() {
      const idList = this.$refs.surveyListRef?.selectArr

      if (idList.length) {
        // TODO：开发注意：是单选就拿最后一个，多选就全部
        const detailList = idList
          .map((id) => {
            return this.$refs.surveyListRef?.getDetailById(id) || null
          })
          .filter((item) => !!item)

        this.$emit('confirm', detailList)
      } else {
        this.$message.info('没有检测到勾选的数据！')
      }
    },

    onRefresh() {
      this.$refs.surveyListRef?.getList()
    },
  },
}
</script>

<style lang="scss">
/* 弹窗样式控制写在 AddConsultServiceReplyDialog.vue 里面就够了，不需要重复写，都会影响到的 */
</style>
